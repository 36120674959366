<template>
  <div class="container">
    <div class="top">
      <div class="left">
        <swiper :options="swiperOption" class="swiper-container" ref="mySwiper">
          <swiper-slide v-for="(item, i) in pic" :key="i" class="swiper-item">
            <img class="swiper-img" :src="item" alt="暂无图片" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev">
            <img src="@img/left.png" alt="" />
          </div>
          <div class="swiper-button-next" slot="button-next">
            <img src="@img/right.png" alt="" />
          </div>
        </swiper>
      </div>
      <div class="right">
        <div class="name">{{ name }}</div>
        <div class="intro" v-html="intro"></div>
        <div class="btns flex justify-between align-center">
          <div class="star" :class="{ on: star }" @click="togglestar()"></div>
          <div class="flex zixun">
            <div class="btn btn-warning-sm" @click="getkefu()">咨询管家</div>
            <div class="btn btn-primary-sm"  @click="openpage('/fbxq',id,true)">发布需求</div>
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="blockstyle">
        <div class="toptitle">
          <img src="@img/fwlc.png" alt="" class="icon" />
          <span>服务流程</span>
        </div>
        <div class="main fwlc">
          <img :src="lcimg" alt="" />
        </div>
      </div>
    </div>
    <div class="block">
      <div class="blockstyle">
        <div class="toptitle">
          <img src="@img/fwlc.png" alt="" class="icon" />
          <span>服务详情</span>
        </div>
        <div class="main fwxq">
           <video :src="video" v-if="video" controls="controls" ></video>
          <div  v-html="fwxq"></div>
         
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  components: {},
  data() {
    return {
      pic: [],
      name: "",
      intro: "",
      video:"",
      star: true,
      fwlc: "",
      lcimg: "",
      fwxq: "",
      swiperOption: {
        loop: true,
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
      id:""
    };
  },
  computed: {
     ...mapState(["isLogin", "userInfo"]),
  },
  mounted() {
      window.scroll({ top: 0, left: 0});
    var id = this.$route.query.id;
    this.id=id;
    this.$http.productdetail({ id }).then((res) => {
      this.fwxq = res.data.content;
      this.name = res.data.title;
      this.star = res.data.is_fav;
      this.pic = res.data.thumb_images;
      this.intro = res.data.description;
      this.video = res.data.video;
    });
    // 获取服务流程图
    this.$http.ad_home().then((res) => {
      this.lcimg = res.data.image;
    });
  },
  methods: {
     ...mapMutations([
      "setLogin",
      "setUserInfo",
      "setLoginDialog",
      "setRegistDialog",
    ]),
    togglestar() {
      if (this.isLogin != 1) {
           this.setLoginDialog(true);
  
        }else{
          this.$http.productfav({id:this.id}).then((res) => {
           this.star = !this.star;
          if(this.star){
             this.$message.success({ message: "收藏成功!" });
          }else{
              this.$message.success({ message: "取消收藏成功!" });
          }
          
       });
    }
      
    },
     getkefu() {
       if (this.isLogin != 1) {
           this.setLoginDialog(true);
      
        }else{
          this.$store.dispatch("setkefu");
        }
      
    },
     openpage(path,id, islogin){
        if (islogin) {
          if (this.isLogin != 1) {
            this.setLoginDialog(true);
        
            return;
          }
      }
        this.$router.push({
            path,
            query: {
              id,
            },
        })
     }
  },
};
</script>
<style scoped lang="scss">
@import "~@/styles/mixin.scss";
.container {
  margin-bottom: 20px;
}
.swiper-button-next,
.swiper-button-prev {
  width: 30px;
  height: 30px;
  img {
    display: block;
  }
}
.swiper-button-next:after,
.swiper-button-prev:after {
  display: none;
}
.fwxq /deep/img {
  display: block;
  margin: 0 auto;
   width: 100%;
}
/deep/.fwxq video{
  margin: 0 auto;
  display: block;
   width: 100%;
   max-height: 500px;
}
.top {
  padding: 20px;
  display: flex;
  background: #fff;
  margin-bottom: 20px;
  .left {
    width: 560px;
    height: 270px;
    margin-right: 20px;
    .swiper-container {
      border-radius: 6px;
      overflow: hidden;
      height: 270px;
    }
    .swiper-container img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .right {
    flex: 1;
    position: relative;
    .star {
      width: 28px;
      height: 28px;
      background: url("~@img/star.png") no-repeat center;
      background-size: 100% 100%;
      cursor: pointer;
      &.on {
        background: url("~@img/staron.png") no-repeat center;
        background-size: 100% 100%;
      }
    }
    .name {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .intro {
      font-size: 16px;
      color: #666;
    }
  }
  .btns {
    position: absolute;
    width: 100%;
    bottom: 0;
    .zixun {
      justify-content: space-between;
      width: 250px;
    }
  }
}
.title {
  font-size: 24px;
  color: $color-theme;
  text-align: center;
  padding-top: 45px;
}
.tip0 {
  width: 30%;
  justify-content: space-around;
  margin-top: 30px;
  color: #666;
  font-size: 16px;
  margin-left: 70%;
}
.content {
  padding: 20px;
  font-size: 16px;
  line-height: 2;
  img {
    display: block;
    width: 100%;
    height: auto;
   
  }
}
.main {
  padding: 20px 0;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.fwlc {
  img {
    margin: 40px auto 30px;
    width: 90%;
    display: block;
  }
}
.block {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.blockstyle {
  flex: 1;
  padding: 12px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  .toptitle {
    position: relative;
    width: 100%;
    padding-bottom: 12px;
    &:after {
      content: "";
      position: absolute;
      left: -12px;
      right: -12px;
      bottom: 0;
      height: 1px;
      background-color: $color-theme;
    }
    .more {
      float: right;
      font-size: 18px;
      display: flex;
      align-items: center;
      transition: all 1s linear;
      cursor: pointer;
      &:hover {
        color: $color-theme;
      }
      img {
        margin-left: 10px;
        margin-top: 2px;
      }
    }
    .icon {
      margin-right: 10px;
      transform: translateY(3px);
    }
  }
}
</style>
